import Title from "@components/UI-Elements/Typography/Title";
import React from "react";
import Layout from "../../components/layout";
import CoinList from "../../components/Modules/dashboard/coin-table";
import NewsApiList from "../../components/Modules/news/newsapi";
import SEO from "../../components/seo";
import Card from "../../components/UI-Elements/Card";
import GoogleTrends from "../../components/UI-Elements/GoogleTrend";

const SmartContractIndex = () => {
  return (
    <Layout>
      <SEO keywords={['smart contract plattformen', 'charts']} title="Smart Contracts" robots="noindex" />
      <Title>Smart Contract Plattformen</Title>
      <div className="grid gap-4">
        <section className="py-4">
          <div className="grid gap-4">
            <Card title="Was sind Smart Contracts?">
              <p className="pb-2">
                Ein Smart Contract, im Deutschen auch intelligenter Vertrag, basiert auf Computerprotokollen. Hierbei handelt es sich somit
                um eine Art digitalen Vertrag, der auf der Blockchain-Technologie basiert. Die Bedingungen der Vereinbarung zwischen
                „Käufer“ und „Verkäufer“ werden dabei direkt in Codezeilen geschrieben. Smart Contracts sind selbstausführende Verträge, das
                heißt, dass sie bei bestimmten zuvor festgelegten Ereignissen selbständig in Kraft treten und daher auch keiner menschlichen
                Überwachung bedürfen. Sind diese Eintrittsbedingungen erfüllt, so veranlasst der Algorithmus automatisch eine Transaktion,
                welche anschließend validiert und in einem Block gespeichert wird. Smart Contracts ermöglichen somit die Durchführung
                vertrauenswürdiger Transaktionen und Vereinbarungen zwischen verschiedenen Parteien. Diese digitalen Verträge sind durchaus
                mit klassischen Verträgen vergleichbar – beispielsweise mit einem Kaufvertrag oder dem Abschluss einer Versicherungspolice.
                Da ein solcher intelligenter Vertrag ohne menschliches Eingreifen abgewickelt wird, lassen sich auch typische Fehlerquellen
                ausschließen.
              </p>
            </Card>
          </div>
        </section>
      </div>
      <section className="py-4">
        <Title level={2}>5 Smart Contract Kryptowährungen</Title>
        <CoinList coins={["ethereum", "solana", "cardano", "avalanche-2", "terra-luna"]} />
      </section>
      <section className="py-4">
        <Title level={2}>Wie sieht das Interesse aus?</Title>
        <Card>
          <p className="mb-3">
            Interessant ist hier, wie sich die neueren Plattformen im Vergleich zu Ethereum beweisen und welcher von diesen das meiste
            Interesse also Suchvolumen hat.
          </p>
          <GoogleTrends time="today 12-m" keywords={["Ethereum", "Solana", "Cardano", "Avalanche", "Terra"]} />
        </Card>
      </section>
      <section className="py-4">
        <Title level={2}>Aktuelle News zu Smart Contract?</Title>
        <NewsApiList keyword={'smart contract'} pageSize={6} />
      </section>
    </Layout>
  );
};

export default SmartContractIndex;
